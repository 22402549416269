.login-container {
  margin-bottom: 5%;
}

:host ::ng-deep {
  .kv-login
    > .mat-mdc-form-field
    .mat-mdc-text-field-wrapper.mdc-text-field.mdc-text-field--filled.mdc-text-field--focused,
  .mat-mdc-form-field .mat-mdc-text-field-wrapper.mdc-text-field.mdc-text-field--filled {
    //background-color: pink;
    //background-color: $kw-colors-white;
  }
}

::ng-deep {
  //.mat-mdc-form-field .mat-form-field-appearance-outline .mdc-text-field--outlined {
  //  outline: none;
  //}
  .kv-login .login-fields .mdc-text-field--filled:not(.mdc-text-field--disabled),
  .kv-login .login-fields .mdc-text-field--disabled.mdc-text-field--filled {
    background-color: rgba(255, 255, 255, 0) !important;
    height: 33px;
  }
}
.inline-loader {
  display: inline-block;
}

.login-container,
.mat-mdc-form-field {
  width: 100%;
}

.login-fields > :not(:last-child) {
  margin-bottom: 8px;
}

.login-error {
  color: $kw-colors-red;
}

.mat-form-field-invalid .mat-mdc-input-element {
  caret-color: $kw-colors-red;
}

.kv-login h4 {
  margin: 0;
}

.login-title {
  font-family:
    Roboto Condensed,
    Helvetica Neue,
    Arial,
    sans-serif !important;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
  font-size: 36px;
  width: 100%;
}

.modal-title {
  font-family:
    Roboto Condensed,
    Helvetica Neue,
    Arial,
    sans-serif !important;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
  width: 100%;
  padding-left: 10px;
}

.header-login {
  border-bottom: 1px solid #ccc;
  width: 100%;
}
.bg-white {
  background-color: #fff;
}

//::ng-deep .icon-outside .close-button {
//  float: right;
//  top: -52px;
//  right: -52px;
//}

::ng-deep .icon-outside .mat-mdc-dialog-container {
  overflow: unset;
}

.mat-mdc-dialog-container {
  display: block;
  //padding: 2px 0px 2px 0px;
  border-radius: 4px;
  box-sizing: border-box;
  overflow: auto;
  outline: 0;
  width: 100%;
  height: 100%;
  min-height: inherit;
  max-height: inherit;
}

.kw-attention {
  color: #fff;
}
.kw-attention,
.rowlightRed {
  background-color: $kw-colors-red;
}
