.form-control {
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.form-control,
output {
  display: block;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
}

.form-group {
  margin-bottom: 15px;
}

::ng-deep .mat-expansion-panel-header.mat-expanded {
  // used !important due to ..
  color: #333 !important;
  background-color: #f5f5f5 !important;
  border-color: #ddd !important;
}

// TBD:: shift this css to main if reused?
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12 {
  position: relative;
  width: 50%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.container-flex {
  width: 100%;
  display: inline-flex;
}

.close-icon {
  color: #ccc;
  transition: 1s ease-in-out;
}

.close-icon:hover {
  transform: rotate(180deg);
  color: #888484;
}

.close-button {
  float: right;
  top: 5px;
  right: 4px;
  width: 30px;
  height: 30px;
  line-height: 15px;
}

.modal-body {
  position: relative;
  padding: 0px;
}

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
  border-bottom: 0px;
}

//.table > tbody > tr > td {
//  padding: 5px;
//  line-height: 1.42857143;
//  border-top: 1px solid #ddd;
//}

.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #f9f9f9;
}

.mat-expansion-panel-body {
  padding: 10px;
}

.table > tbody > tr > td.success,
.table > thead > tr > th.success {
  background-color: #dff0d8;
}

.table > tbody > tr > td.danger,
.table > thead > tr > th.danger {
  background-color: #f2dede;
}

.btn {
  border-radius: 1%;
}

.btn-danger-light,
.btn-danger-light:hover {
  background-color: #fff;
  border-color: rgba(178, 34, 34, 0.5);
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
::ng-deep .mat-form-field-appearance-legacy .mat-form-field-underline {
  height: 0px;
}

::ng-deep .cdk-global-overlay-wrapper {
  justify-content: center !important;
  align-items: center !important;
}

.row {
  margin-left: 0;
  margin-right: 0;
}

.realestate-table-filter,
.realestate-table-filter-small {
  font-weight: 400;
  height: 100%;
  float: left;
  margin-right: 25px;
  margin-bottom: 18px;
}

.realestate-table-filter {
  width: 265px;
}

//.table > tbody > tr > td,
//.table > tbody > tr > th,
//.table > tfoot > tr > td,
//.table > tfoot > tr > th,
//.table > thead > tr > td,
//.table > thead > tr > th {
//  padding: 8px;
//  line-height: 1.42857143;
//  vertical-align: top;
//  border-top: 1px solid #ddd;
//}

tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}
tbody {
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
}

thead {
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
}

.table-responsive {
  min-height: 0.01%;
  overflow-x: auto;
}

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
  border-spacing: 0;
  border-collapse: collapse;
  overflow-x: auto;
}

/* Keeping it hear because  style not listening from main.scss*/
.btn-elegant-green {
  font-weight: 500;
  border-radius: 0;
  border: 1px solid;
  color: #13d96b;
  background-color: rgba(19, 217, 107, 0.05);
  border-color: rgba(19, 217, 107, 0.8);
}

/*margin left right 5 px split*/
.mg-5 {
  margin: 0px 5px;
}

.flex-center {
  display: flex;
  align-items: center;
}

::ng-deep .mat-mdc-paginator-container {
  display: flex;
  align-items: center;
  justify-content: center !important;
  padding: 0 8px;
  flex-wrap: wrap-reverse;
  width: 100%;
}
