.ifcv-ifcviewer-viewer ifcv-viewer {
  height: 100%;
}

#mustafa-bim .ifcv-viewer {
  height: 100%;
}

#mustafa-bim {
  background-color: blue;
}
