// -----------------------------------------------------------------------------
// This file contains styles that are specific to the home page.
// -----------------------------------------------------------------------------
@media (min-width: 0px) and (max-width: 599px) {
  .dont-hide-circle {
    flex-direction: column;
  }

  .dont-hide-circle .flexBoxInner.img {
    display: flex;
    flex-direction: column;
    flex-basis: max-content;
    max-width: 300px;
  }
}

.sectionId_lcc {
  background-color: $kw-colors-white;
  padding: 50px 20px;
}

.sectionId_baukosten {
  padding: 50px 20px;
  background: $kw-color-grey;
}

.sectionId_umbaukosten {
  padding: 50px 20px;
  background: $kw-colors-white;
}

.sectionId_betriebskosten {
  padding: 50px 20px;
  background: $kw-colors-blue;
}

.sectionId_pf {
  padding: 50px 20px;
  background: $kw-color-grey;
}
