// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

/// Regular font family
/// @type List
$text-font-stack: "Roboto Condensed", "Helvetica Neue Light", "Helvetica Neue", "Helvetica", "Arial", sans-serif !default;

/// Code (monospace) font family
/// @type List
$code-font-stack: "Courier New", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", "Monaco", monospace !default;

//keevalue colors
$kw-colors-red: #bb7c7a !default;
$kw-colors-white: #fdfdfe !default;
$kw-colors-green: #88bb7a !default;
$kw-colors-blue: #e5f0f9 !default;
$kw-colors-darkblue: #6980ac !default;
$kw-color-grey: rgba(231, 231, 231, 0.92) !default;
$kw-color-black: #414141 !default;

/// Copy text color
/// @type Color
$text-color: $kw-color-black !default;

/// Main brand color
/// @type Color
$brand-color: $kw-colors-red !default;

/// Light grey
/// @type Color
$light-grey: $kw-color-grey !default;

/// Medium grey
/// @type Color
$mid-grey: rgb(153, 153, 153) !default;

/// Dark grey
/// @type Color
$dark-grey: rgb(68, 68, 68) !default;

/// Container's maximum width
/// @type Length
$max-width: 100% !default;

/// Declares the width of the label in the input form.
$kv-form-label-width: 350px;
/// Declares the width of the input in the input form.
$kv-form-input-width: 453px;

/// Declares the width of the image in the input form.
$kv-form-image-width: 360px;
/// Declares the width of the description in the input form.
$kv-form-description-width: 362px;

$kv-form-slider-width-margin: 10px;

//$max-width: 1180px !default; //this was first - currently doesn't make sense due to whole scss structure.

/// Breakpoints map
/// @prop {String} keys - Keys are identifiers mapped to a given length
/// @prop {Map} values - Values are actual breakpoints expressed in pixels
$breakpoints: (
  "xsmall": 320px,
  "small": 599px,
  "medium": 768px,
  "large": 1024px,
  "xlarge": 1280px,
  "xxlarge": 1499px,
  "huge": 1550px,
) !default;

/// Relative or absolute URL where all assets are served from
/// @type String
/// @example scss - When using a CDN
///   $base-url: 'https://cdn.example.com/assets/';
$base-url: "/assets/" !default;
