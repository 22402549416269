.header-dimension {
  border-bottom: 1px solid #ccc;
  width: 100%;
  height: 3em;
}
h2,
h4 {
  &.kv-dialog-title {
    align-self: center;
    margin-bottom: 0 !important;
    margin-left: 1em !important;
    letter-spacing: 0 !important;
  }
}

.kv-dialog-content {
  > * {
    color: $text-color;
    letter-spacing: 0;
  }

  .kv-calc-table-button {
    background: transparent;
  }
}
