// ------------------------------------------------------------------
// Drawer general
// ------------------------------------------------------------------

.drawer {
  //height: calc(100vh - 98px);
  display: flex;
  float: right;
  background: transparent;
  //right: 10px;
  //top: 83px;
  position: fixed;
  z-index: 5000;
}

// Register container and buttons

.registerButtonContainer {
  max-width: 100px;
  min-width: 100px;
  height: calc(100vh - 98px);
  z-index: 1;
}

.registerButton {
  width: 48px;
  height: 40px;
  margin-top: 15px;
  margin-left: 47px;
  border-color: transparent;
  background-color: $kw-colors-red;
  border-top-left-radius: 10%;
  border-bottom-left-radius: 10%;
  z-index: 1;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.registerButton .material-icons {
  color: white;
  display: flex;
  justify-content: center;
}

.drawerContent {
  display: flex;
  flex-direction: column;
  width: calc(100% - 100px);
  height: calc(100vh - 98px);
  visibility: visible;
  background: white;
  z-index: 6000;
}

.drawerContent-not-visible {
  display: flex;
  flex-direction: column;
  width: calc(100% - 100px);
  height: calc(100vh - 98px);
  visibility: collapse;
  z-index: 1;
}

.drawer-slider {
  float: right;
  height: calc(100vh - 98px);
  width: 5px;
  //background-color: rgb(196, 124, 122);
  background-color: $kw-colors-red;
  cursor: col-resize;
}

// ------------------------------------------------------------------
// Map viewer
// ------------------------------------------------------------------

.mapComponent {
  width: 100%;
  display: inline;
}

// Map menu bar

.mapMenuBar {
  position: absolute;
  top: 0;
  left: 100px;
  right: 0;
  width: 100%;
  height: 40px;
  max-height: 40px;
  min-height: 40px;
  background-color: transparent;
  z-index: 1;
}

.mapMenuBarButtons {
  margin-left: 10px;
  float: left;
  height: 100%;
  display: flex;
  align-items: center;
  background-color: transparent;
  z-index: 1;
}

.mapMenuBarSingleButton {
  margin-top: 30px;
  margin-bottom: 1px;
  margin-right: 5px;
  margin-left: 4px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: $kw-colors-red;
  border: none;
  outline: none;
  z-index: 1;
  cursor: pointer;
}

.mapMenuBarSingleButton i.material-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  color: white;
}

.map-menu-bar-button-kataster {
  background-image: url("/assets/images/map/kataster.png");
}

.map-menu-bar-button-luftbild {
  background-image: url("/assets/images/map/luftbild.png");
}
