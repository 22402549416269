@media (min-width: 0px) and (max-width: 599px) {
  .dont-hide-circle {
    flex-direction: column;
  }

  .dont-hide-circle .flexBoxInner.img {
    display: flex;
    flex-direction: column;
    flex-basis: max-content;
    max-width: 300px;
  }
}

.demo-header {
  font-size: 46px;
}

.brightfont__larger {
  font-size: 18px;
  line-height: 1.5;
}

.flexBoxInner.demo-form {
  display: flex;
  flex-direction: column;
  //flex-basis: max-content;
  //max-width: 402px;
}

.details-container {
}

.interested-container {
  display: flex;
}

.interested-container .interested-item {
  display: flex;
  padding: 5px 0px;
  cursor: pointer;
}

.comments-container {
}
