// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------
@font-face {
  font-family: "Roboto Condensed";
  font-weight: 300;
  font-style: normal;
  src: url("../../assets/fonts/Roboto-Condensed-300.eot");
  src:
    url("../../assets/fonts/Roboto-Condensed-300.eot?#iefix") format("embedded-opentype"),
    local("Roboto Condensed Light"),
    local("Roboto-Condensed-300"),
    url("../../assets/fonts/Roboto-Condensed-300.woff2") format("woff2"),
    url("../../assets/fonts/Roboto-Condensed-300.woff") format("woff"),
    url("../../assets/fonts/Roboto-Condensed-300.ttf") format("truetype"),
    url("../../assets/fonts/Roboto-Condensed-300.svg") format("svg");
}

@font-face {
  font-family: "Roboto Condensed";
  font-weight: 300;
  font-style: italic;
  src: url("../../assets/fonts/Roboto-Condensed-300italic.eot");
  src:
    url("../../assets/fonts/Roboto-Condensed-300italic.eot?#iefix") format("embedded-opentype"),
    local("Roboto Condensed Light Italic"),
    local("Roboto-Condensed-300italic"),
    url("../../assets/fonts/Roboto-Condensed-300italic.woff2") format("woff2"),
    url("../../assets/fonts/Roboto-Condensed-300italic.woff") format("woff"),
    url("../../assets/fonts/Roboto-Condensed-300italic.ttf") format("truetype"),
    url("../../assets/fonts/Roboto-Condensed-300italic.svg") format("svg");
}

@font-face {
  font-family: "Roboto Condensed";
  font-weight: 400;
  font-style: normal;
  src: url("../../assets/fonts/Roboto-Condensed-regular.eot");
  src:
    url("../../assets/fonts/Roboto-Condensed-regular.eot?#iefix") format("embedded-opentype"),
    local("Roboto Condensed"),
    local("Roboto-Condensed-regular"),
    url("../../assets/fonts/Roboto-Condensed-regular.woff2") format("woff2"),
    url("../../assets/fonts/Roboto-Condensed-regular.woff") format("woff"),
    url("../../assets/fonts/Roboto-Condensed-regular.ttf") format("truetype"),
    url("../../assets/fonts/Roboto-Condensed-regular.svg") format("svg");
}

@font-face {
  font-family: "Roboto Condensed";
  font-weight: 400;
  font-style: italic;
  src: url("../../assets/fonts/Roboto-Condensed-italic.eot");
  src:
    url("../../assets/fonts/Roboto-Condensed-italic.eot?#iefix") format("embedded-opentype"),
    local("Roboto Condensed Italic"),
    local("Roboto-Condensed-italic"),
    url("../../assets/fonts/Roboto-Condensed-italic.woff2") format("woff2"),
    url("../../assets/fonts/Roboto-Condensed-italic.woff") format("woff"),
    url("../../assets/fonts/Roboto-Condensed-italic.ttf") format("truetype"),
    url("../../assets/fonts/Roboto-Condensed-italic.svg") format("svg");
}

@font-face {
  font-family: "Roboto Condensed";
  font-weight: 700;
  font-style: normal;
  src: url("../../assets/fonts/Roboto-Condensed-700.eot");
  src:
    url("../../assets/fonts/Roboto-Condensed-700.eot?#iefix") format("embedded-opentype"),
    local("Roboto Condensed Bold"),
    local("Roboto-Condensed-700"),
    url("../../assets/fonts/Roboto-Condensed-700.woff2") format("woff2"),
    url("../../assets/fonts/Roboto-Condensed-700.woff") format("woff"),
    url("../../assets/fonts/Roboto-Condensed-700.ttf") format("truetype"),
    url("../../assets/fonts/Roboto-Condensed-700.svg") format("svg");
}

@font-face {
  font-family: "Roboto Condensed";
  font-weight: 700;
  font-style: italic;
  src: url("../../assets/fonts/Roboto-Condensed-700italic.eot");
  src:
    url("../../assets/fonts/Roboto-Condensed-700italic.eot?#iefix") format("embedded-opentype"),
    local("Roboto Condensed Bold Italic"),
    local("Roboto-Condensed-700italic"),
    url("../../assets/fonts/Roboto-Condensed-700italic.woff2") format("woff2"),
    url("../../assets/fonts/Roboto-Condensed-700italic.woff") format("woff"),
    url("../../assets/fonts/Roboto-Condensed-700italic.ttf") format("truetype"),
    url("../../assets/fonts/Roboto-Condensed-700italic.svg") format("svg");
}
