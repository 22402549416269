.price-table {
  border: none !important;
  .mat-mdc-header-cell {
    color: #0000008a;
    padding: 0;
    border: none;
    font-size: 16px;
  }
  .mat-mdc-cell,
  .mat-mdc-footer-cell {
    padding: 0;
    font-size: 14px;
    border: none;
    letter-spacing: normal;
  }
}

:host ::ng-deep .price-table.mat-mdc-table {
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 15px;
  background-color: inherit;
}

.price-table {
  & tr.mat-mdc-row,
  & tr.mat-mdc-footer-row,
  & tr.mat-mdc-header-row {
    height: 34px;
    border: none;
  }
}

::ng-deep .mat-mdc-table {
  width: 100%;
  box-shadow: unset;
  border: none;
  border-bottom: unset;
}

::ng-deep .mat-mdc-table th {
  font-size: inherit;
}

::ng-deep .mat-mdc-footer-cell {
  letter-spacing: normal;
}

/* Use table-blue-border as a customization for bootstrap's table and table table-bordered */
.table-blue-border > thead > tr > th,
.table-blue-border > tbody > tr > th,
.table-blue-border > tfoot > tr > th {
  border-top: solid transparent;
  border-bottom: 3px solid #e5f0f9;
  padding: 0px 1px 2px 2px;
  border-right-width: 0px;
  border-left-width: 0px;
  line-height: 1.42857143;
}

.table-blue-border > thead > tr {
  border-top: solid transparent;
  //border-bottom: 3px solid #e5f0f9;
  //padding: 0px 1px 2px 2px;
  //border-right-width: 0px;
  //border-left-width: 0px;
  //line-height: 1.42857143;
}

.table-blue-border > colgroup:not(:first-child) {
  border-left: 1px solid #e5f0f9;
}

.table-blue-border > thead > tr > td,
.table-blue-border > tbody > tr > td,
.table-blue-border > tfoot > tr > td {
  border: 1px solid #e5f0f9;
  padding: 1px 1px 2px 2px;
  border-right-width: 0px;
  border-left-width: 0px;
  line-height: 1.42857143;
}
