/*
 * Application global variables.
 */
@use "@angular/material" as mat;
// Angular Material custom theme
// See https://material.angular.io/guide/theming for more details.
// You can also read https://medium.com/@tomastrajan/the-complete-guide-to-angular-material-themes-4d165a9d24d1
// for more insight about Angular Material theming.

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue.
// 3rd party libraries
//$material-design-icons-font-directory-path: "~material-design-icons-iconfont/dist/fonts/";
//@import "material-design-icons-iconfont/src/material-design-icons";
@import "material-design-icons-iconfont/dist/material-design-icons.css";

// Angular Material custom theme
// The mixins below must be included once so we separated them from the variables
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
@include mat.core();

$my-typography: mat.m2-define-typography-config(
  $font-family: "Roboto Condensed",
);

$md-kw-red: (
  50: #f7efef,
  100: #ebd8d7,
  200: #ddbebd,
  300: #cfa3a2,
  400: #c5908e,
  500: #bb7c7a,
  600: #b57472,
  700: #ac6967,
  800: #a45f5d,
  900: #964c4a,
  A100: #fff3f2,
  A200: #ffc1bf,
  A400: #ff8f8c,
  A500: #b57472,
  A700: #ff7673,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A500: #000000,
    A700: #000000,
  ),
);
$md-kw-grey: (
  50: #e0e0e0,
  100: #b3b3b3,
  200: #808080,
  300: #4d4d4d,
  400: #262626,
  500: #000000,
  600: #000000,
  700: #000000,
  800: #000000,
  900: #000000,
  A100: #a6a6a6,
  A200: #8c8c8c,
  A400: #737373,
  A700: #666666,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);
$app-primary: mat.m2-define-palette($md-kw-grey);
// stylelint-disable-next-line value-keyword-case
$app-accent: mat.m2-define-palette($md-kw-red, A500, A500, A500);
// The warn palette is optional (defaults to red).
$app-warn: mat.m2-define-palette($md-kw-red, A500, A500, A500);
// Create the theme object (a Sass map containing all of the palettes).
//$test-lf: mat.font-weight(300)
$app-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $app-primary,
      accent: $app-accent,
      warn: $app-accent,
    ),
    typography: $my-typography,
  )
);

body {
  .cFormElement
    .cInputElement
    .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea)
    .mat-mdc-form-field-infix {
    height: 100%;
  }

  .mdc-text-field {
    padding-top: 0;
    //padding-right: 14px;
    padding-right: 2px;
    padding-left: 2px;
    padding-bottom: 0;
    letter-spacing: normal;
  }
  .mat-mdc-form-field-infix > * {
    letter-spacing: normal;
  }

  .mat-mdc-form-field-flex {
    height: auto;
  }

  .mat-mdc-form-field-infix {
    min-height: auto;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .mat-mdc-input-element,
  .mat-mdc-form-field-icon-suffix {
    margin-top: 6px;
  }
}

//checkbox labels are not bold (standard label in keevalue design is font-weight: 700 <- this might be not a wise decision - however, it is the current design)
.mat-mdc-checkbox label {
  cursor: pointer;
  font-weight: 400;
  letter-spacing: normal;
}

::ng-deep {
  //grey background on input fields.
  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: #0000000a !important;
  }

  .mdc-text-field--disabled.mdc-text-field--filled {
    background-color: transparent !important;
  }
  .mdc-text-field--disabled.mdc-text-field--filled .mdc-text-field__input {
    color: $kw-color-black !important;
  }

  .mdc-dialog .mat-mdc-icon-button .mat-mdc-button-persistent-ripple {
    display: none;
  }

  /**
* this is for the dropdown arrow so we can see it as we change in general the padding on the mdc-text-field (see above)
* which results that the arrow is outside the input field. with this rule we change that.
 */
  .mdc-text-field :where(div mat-select) {
    //padding-top: 0.5em;
    padding-right: 14px;
    //padding-left: 0.3em;
    //padding-bottom: 0;
  }

  /* &.mat-mdc-form-field {
    height: 38px;
  }*/
  /*
 TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
 */
  &.mat-form-field-subscript-wrapper {
    margin-top: 0.1rem;
  }

  //arrow placement
  /*
 TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
 */
  &.mat-select-arrow {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid;
    margin: 0px 0px;
  }

  /**
  mat select arrow placement
 */
  /*
 TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
 */
  &.mat-form-field-appearance-fill .mat-select-arrow-wrapper {
    transform: translateY(-20%);
  }

  //------------------------------------------------------------------------------
  // Change the color intensity of the grey line below the inputs.
  //------------------------------------------------------------------------------
  /*
 TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
 */
  & .mat-form-field-appearance-fill .mat-form-field-underline::before {
    background-color: rgba(0, 0, 0, 0.22);
  }

  //--------------------------------------------------------------------------------
  // background and color on hover
  //--------------------------------------------------------------------------------
  & .mat-mdc-option:hover:not(.mat-option-disabled),
  .mat-option:focus:not(.mat-option-disabled),
  .mdc-list-item:hover:not(.mat-option-disabled) {
    background: $kw-colors-red !important;
    color: #fff;
    letter-spacing: normal;
  }

  .mat-mdc-option * {
    letter-spacing: normal !important;
    font-size: 14px;
  }
}

/*
* old just for reference I will not remove it
 TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
 */
//::ng-deep .mat-form-field-flex > .mat-form-field-infix {
//  padding: 0.08em 0px !important;
//  border-top: 0;
//}

/*
old just for reference I will not remove it
 TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
 */
//::ng-deep .mat-form-field-label-wrapper {
//  top: -1.5em;
//}

/*
* old just for reference I will not remove it
 TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
 */
//::ng-deep .mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
//  transform: translateY(-1.1em) scale(0.75);
//  width: 133.33333%;
//}

/*
* old just for reference I will not remove it
 TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
 */
//::ng-deep .mat-form-field-flex > .mat-form-field-infix {
//  padding: 0.08em 0px !important;
//  border-top: 0;
//}

/*
*old just for reference I will not remove it
 TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
 */
//::ng-deep .mat-form-field-label-wrapper {
//  top: -1.5em;
//}

::ng-deep
  .mat-mdc-text-field-wrapper.mdc-text-field--focused
  .mat-mdc-form-field
  .mdc-floating-label
  .mat-mdc-floating-label {
  transform: translateY(-1.1em) scale(0.75);
  width: 133.33333%;
}

//input fields bigger for certain pages: (where we use floating labels) e.g. login, register, password reset
//#page_register .mat-form-field,
//app-register-form .mat-form-field {
//  height: 70px;
//  padding: 13px 0;
//}

::ng-deep {
  .kv-login {
    .mat-mdc-text-field-wrapper .mat-mdc-form-field-infix .mat-mdc-floating-label {
      top: 50%;
      letter-spacing: normal;
      font-size: 14px;
      overflow: visible;
    }

    .mdc-text-field--filled .mdc-floating-label--float-above {
      transform: translateY(-100%) scale(0.7);
      overflow: visible;
    }
    //remove background on input fields when they are focused.
    .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay,
    .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
      opacity: 0;
    }
  }

  app-register-form,
  #password_reset,
  #password_finish,
  #accept_invite,
  #page_password,
  #page_settings,
  #page_register {
    .mat-mdc-form-field {
      height: 70px;
      padding: 13px 0;
      letter-spacing: normal;
    }

    /*
 TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
 */
    .mat-mdc-form-field-flex > .mat-mdc-form-field-infix {
      letter-spacing: normal;
      font-size: 14px;
    }

    /*
 TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
 */
    .mat-mdc-form-field-flex > .mat-mdc-form-field-infix .mdc-floating-label {
      top: -0.84375em;
      padding-top: 0.84375em;
    }

    /*
 TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
 */
    .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
      transform: translateY(-1.1em) scale(0.75);
      width: 133.33333%;
    }
  }

  //grey / black line below input field.
  .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
    border-bottom-color: $mid-grey !important;
  }
  //changes color of placeholder to our standard grey.
  .mdc-text-field ::placeholder {
    color: $mid-grey !important;
  }

  //remove hover & focused effect which makes the input field slighty darker.
  .mat-mdc-form-field.mat-focused div .mat-mdc-form-field-focus-overlay,
  .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay,
  .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
    opacity: 0;
  }

  /**
  setting text inside inputs (including placeholder to 14px).
   */
  .mat-mdc-text-field-wrapper {
    font-size: 14px;
  }

  .mat-mdc-button > mat-icon.mat-icon {
    width: 1.5rem;
    height: 1.5rem;
  }
}

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
//@include angular-material-theme($app-theme);

//activate theme for all components
@include mat.all-component-themes($app-theme);
