.cFormElement.usage-wrapper {
  height: 100%;
}

::ng-deep {
  .compact-usages {
  }
  .compact-usages-s {
    min-width: 30px;
    /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
    .mat-select-trigger {
      display: inline-flex;
      align-items: stretch;
      cursor: pointer;
      position: relative;
      box-sizing: border-box;
      width: 100%;
      text-overflow: clip;
      min-width: 30px;
      right: 9%;
      flex-wrap: nowrap;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
    .mat-mdc-form-field-flex .mat-mdc-select-arrow-wrapper {
      display: none;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .compact-usages-percentage .mat-form-field-flex {
      padding: 0.75em 0.1em 0 0;
    }
  }
  .compact-usages-xs {
    min-width: 30px;
    /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
    .mat-select-trigger {
      display: inline-flex;
      align-items: stretch;
      cursor: pointer;
      position: relative;
      box-sizing: border-box;
      width: 100%;
      text-overflow: clip;
      min-width: 30px;
      right: 50%;
      /* justify-content: flex-start; */
      flex-wrap: nowrap;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
    .mat-mdc-form-field-flex .mat-mdc-select-arrow-wrapper {
      display: none;
    }

    //eventuell nur bei der % eingabe.
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .compact-usages-percentage .mat-form-field-flex {
      padding: 0.75em 0.1em 0 0;
    }
  }
}
