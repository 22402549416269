// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------

//-----------------------------------------------------------------------------------------
// Dynamic Image part
//------------------------------------------------------------------------------------------
.bannerTitle {
  position: relative;
  color: #fff;
  z-index: 1000;
  text-align: left;
  //letter-spacing: 2px;
  white-space: nowrap;
}

.bannerTitle > h1 {
  font-size: 70px;
  color: inherit;
}

::ng-deep .header-bolt-font .mat-mdc-menu-item span.mat-mdc-menu-item-text {
  font-weight: 400;
  //letter-spacing: 1.1px;
}

.navbar .container button {
  height: 100%;
  font-size: 16px;
  letter-spacing: normal;
}
