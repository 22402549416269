.cLabel .info-btn-box.slider,
.cLabelSlider .info-btn-box.slider {
  padding-top: 1px;
}

.hlkSlider {
  min-height: 165px;

  & > .content.cFormElement {
    height: 25px;
  }
}

//needed for baseline size of the slider
.mat-mdc-slider .mdc-slider .mdc-slider__thumb {
  display: flex;
  left: -12px;
  outline: none;
  position: absolute;
  user-select: none;
  height: 24px;
  width: 24px;
}
app-hlk-advanced-slider-input .info-btn-box .btn.btn-transparent-orange.btn-sm,
app-slider-input .info-btn-box .btn.btn-transparent-orange.btn-sm {
  align-content: start;
  display: inline;
  top: -2px;
  margin-top: 0;
  padding: 0;
  align-items: start;
  position: initial;
}

/*TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version.*/
::ng-deep .mdc-slider__thumb .mdc-slider__thumb-knob {
  border: solid white 6px;
  outline: $kw-color-grey;
  outline-style: solid;
  outline-width: 0.5px;
}

.compact-slider,
.compact-slider mat-slider {
  display: inline-flex;
  width: 100%;
  height: 100%;
  //overflow:visible;
}

::ng-deep .mdc-slider.always-show-slider-label .mdc-slider__value-indicator {
  //.mdc-slider .mdc-slider__value-indicator {
  transform: none !important;
  //opacity: 1 !important;
}

/*TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version.*/
:host ::ng-deep .mat-slider-thumb-container {
  z-index: 8;

  & > * {
    z-index: 8;
  }
}

/*TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version.*/
:host ::ng-deep .mat-slider-horizontal {
  height: 0;
  /*TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version.*/
  .mat-slider-wrapper {
    //top: 50%;
    top: 0;
    //left: 0;
    //right: 0;
    bottom: 0;
    //bottom:50%;
  }
}

/**
slider horizontal line
 */

:host ::ng-deep .mat-mdc-slider.mdc-slider--disabled {
  & .mdc-slider__track--inactive {
    height: 2px;
    background-color: rgba(0, 0, 0, 0.1);
  }

  & .mdc-slider__track--active_fill {
    height: 2px;
    border-color: transparent;
  }
}

:host ::ng-deep .mdc-slider__track {
  height: 2px;

  & .mdc-slider__track--active,
  & .mdc-slider__track--inactive {
    height: 2px;
    background-color: rgba(0, 0, 0, 0.1);
  }

  & .mdc-slider__track--active_fill {
    height: 2px;
    border-color: transparent;
  }
}

.presetMark,
.boxplot {
  pointer-events: none;
}

:host ::ng-deep {
  .mat-mdc-slider {
    padding: 0;
    margin: 0 $kv-form-slider-width-margin; //adding margin on both sides so the slider bubble is aligned with the other inputs.
    //height:30px;
  }

  .mdc-slider .mdc-slider__track {
    top: 30%;
    z-index: 1;
  }

  /**
    adjusting box to not overlap with the draggable part of the slider (otherwise it is not responsive).
   */
  .mat-mdc-slider-visual-thumb .mdc-slider__value-indicator .mdc-slider__value-indicator-text {
    font-size: 10px;
    font-weight: 400;
    transform: translateY(-9px);
    height: 15px;
  }

  .mdc-slider .mdc-slider__thumb,
  .mdc-slider--disabled .mdc-slider__thumb {
    left: -12px;
    height: 24px;
    width: 24px;
    z-index: 10;
  }

  /**
  declares the size of the drag button
 */
  .mat-mdc-slider .mdc-slider__thumb:hover .mdc-slider__thumb-knob,
  .mat-mdc-slider .mdc-slider__thumb-knob,
  .mat-mdc-slider.mdc-slider--disabled .mdc-slider__thumb-knob {
    background-color: #87939c;
    overflow: visible;
    top: 10px;
    height: 18px;
    width: 18px;
    outline: 0.1px solid rgba(229, 229, 229);
    border: 5px solid white;
  }

  .mat-mdc-slider .mdc-slider__thumb--focused .mdc-slider__thumb-knob:focus,
  .mat-mdc-slider .mdc-slider__thumb--focused .mdc-slider__thumb-knob {
    background-color: $kw-colors-red;
    overflow: visible;
    top: 10px;
    height: 18px;
    width: 18px;
    outline: 0.1px solid rgba(229, 229, 229);
    border: 5px solid white;
  }

  //add the follwing to the slider thumb div to mimic the thumb knob pushed.
  //mat-mdc-slider-visual-thumb mdc-slider__thumb--with-indicator mdc-slider__thumb--focused
  .mat-mdc-slider.mdc-slider .mdc-slider__thumb .mat-mdc-slider-visual-thumb .mdc-slider__value-indicator {
    background-color: transparent; //label has no background
    border: none;
    //color: $kw-color-black!important;
    transition: none;
    transform: scale(1);
    height: 1em;
  }

  .mat-mdc-slider.mdc-slider .mdc-slider__thumb .mdc-slider__value-indicator-container {
    bottom: 24px;
    height: 1px;
  }

  .mat-mdc-slider.mdc-slider .mdc-slider__thumb .mdc-slider__value-indicator {
    height: 0;
  }

  .mat-mdc-slider.mdc-slider.mdc-slider--discrete .mdc-slider__input {
    height: 24px;
    transform: translateY(-2px);
    width: calc(100% + 19px) !important;
    left: -9px !important;
  }

  //disable ripple it seems like you cant turn it of in the html
  .mat-mdc-slider .mat-ripple.mat-mdc-focus-indicator {
    display: none;
  }

  .mdc-slider__thumb .mdc-slider__value-indicator {
    background-color: transparent;
  }

  .mdc-slider .mdc-slider__thumb--with-indicator .mdc-slider__value-indicator {
    transition: none;
  }

  .mdc-slider .mat-ripple-element .mat-mdc-slider-hover-ripple {
    transition: none;
    transform: none;
  }

  .mdc-slider .slider__thumb .mdc-slider__value-indicator {
    transition: none;
  }

  .mat-mdc-slider .mdc-slider__value-indicator {
    color: $kw-color-black;
  }

  //removing arrow that points towards the slider thumb
  .mat-mdc-slider .mdc-slider__value-indicator::before {
    border: none;
  }

  /*TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version.*/
  .mat-mdc-slider.mat-slider-disabled.mat-accent .mat-slider-thumb {
    background-color: $kw-colors-white;
    opacity: 60%;
  }

  //.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label,
  .mat-mdc-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label,
  .mat-slider.mat-accent .mat-slider-thumb-label,
  .mat-slider.mat-accent .mat-slider-thumb-label:hover {
    //background-color: $kw-colors-red;
  }

  //moving label above handle bit down.
  /*TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version.*/
  .mat-slider-horizontal .mat-slider-thumb-label {
    top: -31px;
  }

  //  when focused showing the label
  //  .mat-slider:not(.mat-slider-disabled).cdk-focused .mat-slider-thumb-label-text {
  //    opacity: 1;
  //}

  //.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label,
  .mat-mdc-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label,
  .mat-slider.mat-accent .mat-slider-thumb-label,
  .mat-slider.mat-accent .mat-slider-thumb-label:hover {
    background-color: transparent;
    /*TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version.*/
    .mat-slider-thumb-label-text {
      transform: translate(1px, 1px) rotate(-45deg);
    }
  }

  /*TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version.*/
  .mat-mdc-slider:not(.mat-slider-disabled).cdk-focused.mat-slider-thumb-label-showing .mat-slider-thumb {
    transform: scale(0.7);
  }
}
