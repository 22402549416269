/**
 * Basic typography style for copy text
 */

//body {
//  color: $text-color;
//  font: normal 125% / 1.4 $text-font-stack;
//}

.black-text {
  color: $kw-color-black;
}
