/* ngx-pagination styles below overrides the default css style*/
::ng-deep .ngx-pagination .current {
  padding: 0.1875rem 0.625rem !important;
  background-color: #bb7c7a !important;
  color: #fefefe !important;
  cursor: default !important;
  padding: 0.5rem 1rem !important;
  border-radius: 9% !important;
}

::ng-deep .ngx-pagination .show-for-sr {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  overflow: hidden !important;
  // clip: rect(0, 0, 0, 0) !important;
  background-color: #bb7c7a !important;
}

::ng-deep .ngx-pagination li {
  margin-right: 0.2rem !important;
  border-radius: 0 !important;
  border: 1px solid #bb7c7a;
  color: #bb7c7a !important;
}

::ng-deep .ngx-pagination a,
.ngx-pagination button {
  color: #bb7c7a !important;
  display: block;
  padding: 0.1875rem 0.625rem;
  border-radius: 0;
}

.text-center {
  text-align: center;
}
