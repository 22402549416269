#addition-box {
  //display: flex;
  overflow: visible;

  &.cFormElement {
    float: left;
    padding-left: 0;
    font-size: 14px;
  }

  &.cInputElement .addition-input {
    flex-direction: row;
    //display: flex; //not needed.
  }
  //&.cInputElement.addition-input {
  //  display: flex;
  //  flex-direction: row;
  //  justify-content: center;
  //  align-items: center;
  //}
}

.addition-wrapper {
  display: flex;
}

.building-container .mat-mdc-form-field {
  width: 100%;
}

//in favor of percentage
//.building-container .mat-form-field.additionInputBkp {
//  width: 180px;
//}

.addition-input {
  .addition-label {
    width: 82%;
    display: inline-block;
  }
  .addition-button-container {
    float: right;
    width: 17%;
    min-width: 52px;
    height: 100%;
    text-align: center;
    top: -3px;
    position: relative;
  }
  .add-button {
    & :hover {
      cursor: pointer;
    }
    text-align: center;
    font-weight: 500;
    border-radius: 0;
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    background-color: transparent;
    height: 100%;
  }
  .remove-button {
    & :hover {
      cursor: pointer;
    }
    text-align: center;
    font-weight: 500;
    border-radius: 0;
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    background-color: transparent;
    height: 100%;
  }

  .inputFieldAdditionValue {
    max-width: 49%;
    margin-right: 5px;
    display: inline-block;
  }

  .additionInputBkp {
    max-width: 49%;
    margin-right: 0;
    display: inline-block;
  }
}

#addition-box.rebuild .addition-input {
  .inputFieldAdditionValue {
    max-width: 32%;
    margin-right: 1%;
    display: inline-block;
  }

  .additionInputBkp {
    max-width: 33%;
    margin-right: 0;
    display: inline-block;
  }
  .target-type-input {
    max-width: 31%;
    margin-left: 1%;
    display: inline-block;
  }
}

@media (min-width: 0px) and (max-width: 599px) {
  #addition-box {
    .addition-wrapper {
      display: flex;
      flex-direction: column;
    }
  }
}
