#page_register .mat-mdc-form-field,
app-register-form .mat-form-field {
  height: 70px;
  padding: 13px 0;
}

::ng-deep {
  app-register-form,
  #page_register {
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-flex > .mat-form-field-infix {
      padding: 0.8em 0px !important;
    }
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    transform: translateY(-1.1em) scale(0.75);
    width: 133.33333%;
  }
}
