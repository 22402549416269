//importing library include media to deal intelligently with media queries :)
@import "include-media/dist/include-media";

.sliderValueBox {
  display: inline-grid;
  background-color: rgba(0, 0, 0, 0.04);
}

.WKHeader {
  overflow: visible;
}

.sliderValueBoxLightGrey {
  display: inline-grid;
  background-color: rgb(243 243 243);
}

.sliderBox {
  position: absolute;
  padding: 0px 0px;
  //margin-left: 280px;
  margin-top: 0px;
}

/******************
** Slider Header and Background
 ******************/

.sliderValueBox > label,
.sliderValueBoxLightGrey > label {
  text-align: center;
  font-size: 12px;
}

.greyBoxComplexity {
  height: 190px;
}

.greyBoxRebuild {
  height: 373px;
  background-color: rgba(0, 0, 0, 0.04);
}

.greyBoxComplexityInsur {
  height: 153px;
}

.greyBoxComplexityInsurGvbs {
  height: 194px;
}

.greyBoxQuality {
  height: 230px;
}

.greyBoxQuality-reference {
  height: 240px;
}

.greyBoxRebuildQuality {
  /*height: 140px;
  this is the real one
  */
  height: 187px;
}

.greyBoxQualityInsur {
  height: 191px;
}

.greyBoxQualityOpCost {
  height: 352px;
}

.greyBoxQualityOpCost2 {
  height: 185px;
}

.sliderValueBox > label,
.sliderValueBoxLightGrey > label {
  margin-bottom: 0px;
}

.sliderBox {
  .box-25 {
    width: 24.58%;
  }
  .box-37 {
    width: 37%;
  }

  .de .v1 {
    display: inline-flex;
    float: left;
    margin-left: 0px;
    margin-right: 0;
    width: 50%;
    font-size: 12px;
    font-weight: normal;
    margin-top: 2px;
  }

  .de .v2 {
    display: inline-block;
    margin-left: 0;
    margin-right: 0;
    width: 50%;
    padding-left: 6%;
    font-size: 12px;
    font-weight: normal;
    margin-top: 2px;
  }

  .de .v3 {
    display: inline-block;
    margin-right: 0;
    margin-left: 0;
    width: 32%;
    padding-left: 5%;
    font-size: 12px;
    font-weight: normal;
    margin-top: 2px;
  }

  .de .v4 {
    display: inline-block;
    margin-right: 0;
    margin-left: 0;
    width: 32%;
    padding-left: 8%;
    font-size: 12px;
    font-weight: normal;
    margin-top: 2px;
  }

  .de .v5 {
    display: inline-block;
    margin-right: 0;
    margin-left: 0;
    width: 32%;
    padding-left: 11%;
    font-size: 12px;
    font-weight: normal;
    margin-top: 2px;
  }

  .de .v6 {
    display: inline-block;
    position: relative;
    margin-right: 0;
    margin-left: 0;
    width: 32%;
    padding-left: 14%;
    font-size: 12px;
    font-weight: normal;
    margin-top: 2px;
  }

  .de .v7 {
    display: inline-block;
    position: relative;
    margin-right: 0;
    margin-left: 0;
    width: 32%;
    padding-left: 17%;
    font-size: 12px;
    font-weight: normal;
    margin-top: 2px;
  }

  .de .v8 {
    display: inline-block;
    float: right;
    margin-right: 0;
    margin-left: 0;
    width: 32%;
    text-align: right;
    font-size: 12px;
    font-weight: normal;
    margin-top: 2px;
  }
}

.slider-box-value-label {
}

.sliderBox .sliderValueBox span label {
  width: 2em;
}

.greyBoxQualityLca {
  height: 151px;
}

@media screen and (max-width: 599px) {
  .greyBoxQualityLca {
    height: 0;
  }
}

@include media(">=huge") {
  .cInputElement .sliderBox {
    margin-left: 0px;
    width: $kv-form-input-width;
  }
}
