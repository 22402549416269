.audit-table-border {
  border-collapse: collapse !important;
  border: 1px solid #ddd !important;
}

.audit-table-header,
.audit-table-row {
  height: 2rem !important;
}

.flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.mat-header-audit {
  font-weight: bold !important;
}

.mat-mdc-row:nth-child(2n + 1) {
  background-color: #f9f9f9 !important;
}
