@use "sass:color";
// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------
.close-button {
  float: right;
  top: 24px;
  right: 4px;
  width: 30px;
  height: 30px;
  line-height: 15px;
  border: none;
}

.btn-lg {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
}

.btn-lg.mat-mdc-button {
  height: 100%;
  padding: 9px 32px;
  font-size: 24px;
  line-height: 1.632;
  border-radius: 1%;
  letter-spacing: 1.632px;
}

.mat-primary.submit-btn {
  width: 100px;
  border-radius: 0;
  border: 1px solid;
  color: $kw-colors-red;
  cursor: pointer;
  letter-spacing: normal;

  &.mat-mdc-button:not(:disabled) {
    background-color: hsla(2, 32%, 61%, 0.05);
    border-color: hsla(2, 32%, 61%, 0.8);
    color: $kw-colors-red;
    cursor: pointer;
  }
}

.btn-primary-kv.mat-mdc-button:not(:disabled) {
  color: #fff;
  background-color: $kw-colors-red;
  border-color: $kw-colors-red;
  padding: 12px 18px;
  font-size: 24px;
  line-height: 1.3333333;
  border-radius: 6px;
  box-shadow: unset;
  z-index: 0;
  transform: unset;
  transition: unset;
  height: 100%;
  letter-spacing: normal;
}

kv-one-level-tree button.fake-tree {
  position: absolute;
  left: -3%;
  background: transparent;
}

.fake-tree {
  padding: 0 !important;
  width: 24px !important;
  height: 24px !important;
}

.btn-transparent-orange.info-btn,
.btn-transparent-orange.info-btn:hover,
.info-btn,
.info-btn:hover {
  padding-top: 6px;
  padding-right: 3px;
  border: none;
}

.info-btn-top-padding-4 {
  padding-top: 4px;
}
.info-btn-top-padding-6 {
  padding-top: 6px;
}
.info-btn-box {
  float: right;
  padding-right: 5px;
}

.close-icon {
  color: #ccc;
  //transition: 1s ease-in-out;
}

.btn-transparent-orange:hover,
.btn-transparent-orange:active,
.btn-transparent-orange.active,
.btn-transparent-orange.active:focus {
  border-top: 1px solid rgba(187, 124, 122, 0.8);
  border-left: 1px solid rgba(187, 124, 122, 0.8);
  border-right: 1px solid rgba(187, 124, 122, 0.8);
  color: rgba(187, 124, 122, 1);
  background-color: rgba(187, 124, 122, 0.1);
  box-shadow: none;
  outline: none !important;
  cursor: pointer;

  &:not(:disabled) {
  }
}

.btn-transparent-orange:focus {
  color: rgba(187, 124, 122, 1);
  background-color: transparent;
  box-shadow: none;
  outline: none !important;
}

.btn-elegant-orange {
  font-weight: 500;
  border-radius: 0;
  border-top: 1px solid;
  border-left: 1px solid;
  border-right: 1px solid;
  border-bottom: 1px solid;
  color: rgba(187, 124, 122, 1);
  background-color: rgba(187, 124, 122, 0.05);
  border-color: rgba(187, 124, 122, 0.8);
}

.btn-elegant-orange:hover,
.btn-elegant-orange:focus {
  color: #333;
  background-color: transparent;
  //box-shadow: none;
  cursor: pointer;
  //outline: none !important;
}

.btn.btn-elegant-orange.kv-button-border {
  border: 1px solid !important;
  border-radius: 0 !important;
}

.btn.btn-elegant-orange.kv-button-border-lg-font {
  border: 1px solid !important;
  border-radius: 0 !important;
  font-size: 18px !important;
}

::ng-deep {
  .btn-elegant-orange.mat-mdc-raised-button:not(:disabled),
  .btn-elegant-orange.mat-mdc-button:not(:disabled) {
    font-weight: 500;
    border-radius: 0;
    border: 1px solid;
    color: $kw-colors-red;
    background-color: hsla(2, 32%, 61%, 0.05);
    border-color: hsla(2, 32%, 61%, 0.8);
    line-height: 30px;
    cursor: pointer;
    letter-spacing: normal;
  }
}

.kv-button-border.white-bg {
  border: 1px solid !important;
  border-radius: 0 !important;
  font-size: 18px !important;
  border-color: white;
  color: white;
}

.kv-attention {
  color: #fff;
}
.kv-attention,
.rowlightRed {
  background-color: #c88a88;
}

.btn.btn-elegant-orange.btn-width-max {
  width: 100%;
}

.close-icon:hover {
  transform: rotate(180deg);
  color: #888484;
}

::ng-deep .mat-mdc-button.kv-align {
  float: right;
  display: inline;
  width: 130px;
  margin-top: 1px;
}

//otherwise label and img will not be on the same level.(youtube button in process form for instance) but also on landing page
.mat-mdc-button img {
  vertical-align: sub;
}

/**
Info button spacing top
 */
app-info-box-button button .btn.btn-trasparent-orange .bt-sm {
  margin-top: 2px;
}

.btn.btn-elegant-white,
.btn-elegant-white.mat-mdc-button:not(:disabled) {
  font-weight: 500;
  border-radius: 0;
  border: 1px solid;
  color: #fff;
  background-color: hsla(0, 0%, 100%, 0.1);
  border-color: hsla(0, 0%, 100%, 0.8);
  font-family: "Roboto Condensed", "Helvetica Neue", "Arial", "sans-serif";
}

.btn.btn-elegant-white:hover,
.btn.btn-elegant-white:active,
.btn.btn-elegant-white.active,
.btn.btn-elegant-white.active:focus {
  border-top: 1px solid;
  border-left: 1px solid;
  border-right: 1px solid;
  color: rgba(255, 255, 255, 1);
  background-color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.8);
  outline: none !important;
}

.btn.btn-elegant-white:focus {
  color: rgba(255, 255, 255, 1);
  background-color: transparent;
  box-shadow: none;
  outline: none !important;
}

.btn-elegant-black:active,
.btn-elegant-black:focus,
.btn-elegant-black.mat-mdc-button {
  font-weight: 500;
  border-radius: 0;
  border-top: 1px solid;
  border-left: 1px solid;
  border-right: 1px solid;
  border-bottom: 1px solid;
  color: rgba(0, 0, 0, 1);
  background-color: rgba(0, 0, 0, 0.05);
  border-color: rgba(0, 0, 0, 0.8);
  letter-spacing: normal;
}

//.btn-elegant-black.active,
//.btn-elegant-black.active:focus,
//.btn-elegant-black:active,
//.btn-elegant-black:hover {
//  border-top: 1px solid;
//  border-left: 1px solid;
//  border-right: 1px solid;
//  color: #000;
//  background-color: rgba(0, 0, 0, 0.1);
//  border-color: rgba(0, 0, 0, 0.8);
//  outline: none !important;
//}

.calculate-btn {
  font-weight: 500;
  border-radius: 0;
  border-top: 1px solid;
  border-left: 1px solid;
  border-right: 1px solid;
  border-bottom: 1px solid;
  color: rgba(187, 124, 122, 1);
  background-color: rgba(187, 124, 122, 0.05);
  border-color: rgba(187, 124, 122, 0.8);
  cursor: pointer;

  &:hover {
    color: #333;
  }
}

.red-btn-with-border {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border-radius: 0;
  border-top: 1px solid;
  border-left: 1px solid;
  border-right: 1px solid;
  border-bottom: 1px solid;
  color: rgba(187, 124, 122, 1);
  background-color: rgba(187, 124, 122, 0.05);
  border-color: rgba(187, 124, 122, 0.8);
}

//----------
// Arrow button float in mat-select
//----------
.float-right {
  float: right;
  height: 15px;
  margin-bottom: 2px;
}

.kv-background-transparent {
  background-color: transparent;
}
