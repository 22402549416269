// -----------------------------------------------------------------------------
// When having several themes, this file contains everything related to the
// default one.
// -----------------------------------------------------------------------------
// * Global application theme.
// * Framework overrides and customization goes here.

// stylelint-disable-next-line selector-max-universal

/**
remove box-shadow on mat-card
 */
.mat-mdc-card {
  padding: 0;
  box-shadow: none !important;
}

// -----------------------------------------------------------------------------
// Bulletpoints
// -----------------------------------------------------------------------------
.modalBulletPoint {
  padding-left: 30px;
}

.modalBulletPoint > ul {
  list-style: none;
}

.modalBulletPoint > ul > li::before {
  content: "•";
  color: #000000;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}
