// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
}

* {
  box-sizing: border-box;
}

.biggerFont {
  font-size: 16px;
  line-height: 1.5;
}

html,
body {
  display: flex;
  flex-direction: column;
  margin: 0;
  height: 100%;
  font-family: "Roboto Condensed", "Helvetica Neue", Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #414141;
  background-color: white;
  letter-spacing: normal;
  //font-size: 16px;
}

li,
a,
div,
ul,
img,
p {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
  box-sizing: inherit;
}

.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.grid {
  display: grid;
}

// Flex/grow/shrink properties https://developer.mozilla.org/en-US/docs/Web/CSS/flex.
.flex-1 {
  flex: 1;
} // Same as flex: 0 0 0 (grow, shrink, basis 0). Has similar effect to width: 100%;
.flex-grow-0 {
  flex-grow: 0;
}
.flex-grow-1 {
  flex-grow: 1;
} // Same as flex: 1 1 auto (grow, shrink, basis auto). For spacer, etc.
.flex-grow-9 {
  flex-grow: 9;
} // Same as flex: 9 1 auto (grow, shrink, basis auto). For spacer, etc.

// Justify content.
.space-start {
  justify-content: flex-start;
}
.space-between {
  justify-content: space-between;
} // Used very often with flex-row.
.justify-center {
  justify-content: center;
} // Used to center something via flexbox.
.justify-end {
  justify-content: end;
}
// Align items. (Naming inspiration: https://tailwindcss.com/docs/align-items.)
.items-center {
  align-items: center;
} // Used very often with flex-row.
.items-start {
  align-items: start;
}

.alert-danger {
  color: $kw-colors-red;
  background-color: #f2dede;
  border-color: #ebccd1;
}

.alert-success {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}

.alert {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
}

.lazy-loading-min-height-500 {
  min-height: 500px;
}
.lazy-loading-min-height-600 {
  min-height: 600px;
}
.lazy-loading-min-height-100 {
  min-height: 100px;
}
